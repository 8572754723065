import requestService from "../requestService";
import store from '@/store';

export default {
	async sendCallback(form) {
		const response = await requestService.post(`/v1/callback`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async sendReserve(form) {
		const response = await requestService.post(`/v1/reserve`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
}