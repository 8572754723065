<template>
  <v-col class="px-0 py-0" style="text-align: center">
    <success-snackbar v-if="showSuccessSnackbar" :snackbarText="snackbarText" />
    <v-main>
      <v-container>
        <page-step
          :pages="[{ id: 1, name: 'Оренда автобусів', link: '/rent_bus' }]"
        />
      </v-container>
    </v-main>
    <div class="pageNameBackground" v-if="!$vuetify.breakpoint.smAndDown">
      {{ "rent_bus_head_label" | localize }}
      <div class="textOnBackground">
        {{ "rent_bus_online_label" | localize }}
      </div>
    </div>
    <h2
      class="pageName"
      :style="
        $vuetify.breakpoint.smAndDown
          ? 'margin: 20px 0px 0px 0px'
          : 'margin: 70px 0px 0px 0px'
      "
    >
      {{ "rent_bus_title" | localize }}
    </h2>
    <v-main>
      <v-container>
        <p
          style="color: #989898"
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'margin: 20px 0px 0px 0px;'
              : 'margin: 40px 0px 0px 0px;'
          "
          class="pageText"
        >
          {{ "rent_bus_subtitle" | localize }}
        </p>
        <v-row
          no-gutters
          align="center"
          justify="center"
          style="margin-top: 20px"
        >
          <v-col cols="12" xl="3" lg="3" md="3" sm="12" class="px-0 py-0">
            <v-text-field
              color="#E2E2E2"
              background-color="#FFF"
              outlined
              dense
              placeholder="Введіть номер телефону"
              :label="'phone_number_label' | localize"
              style="border-radius: 10px"
              height="48px"
              hide-details
              v-model="phone_number"
              :error-messages="phoneNumberError"
              v-mask="'+###############'"
            />
          </v-col>
          <submit-button
            style="height: 48px !important"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'width: 100% !important;margin-top: 20px'
                : 'margin-left: 20px; width: 200px !important;'
            "
            @click="callback"
            >{{ "send_btn" | localize }}</submit-button
          >
        </v-row>
      </v-container>
    </v-main>
    <div
      class="rentBusDescription"
      style="margin: 40px 0px; border-radius: 0px"
    >
      <v-main>
        <v-container>
          <v-row no-gutters align="center">
            <v-col
              class="px-0 py-0"
              style="padding-right: 5px; text-align: left"
            >
              <p class="rentBusTitle" style="margin-bottom: 12px">
                {{ "bus_rental_types_title" | localize }}
              </p>
              <div
                v-html="$options.filters.localize('bus_rental_types_text')"
                class="rentBusText"
              />
            </v-col>
            <v-col
              :cols="$vuetify.breakpoint.smAndDown ? '12' : ''"
              class="px-0 py-0"
              style="padding-left: 5px"
            >
              <img
                src="@/assets/img/rentBusImg.png"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '580px'"
                :height="
                  $vuetify.breakpoint.smAndDown ? 'max-content' : '320px'
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </div>
    <v-main>
      <v-container>
        <v-row no-gutters style="margin: 70px 0px">
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="py-0"
            style="margin-bottom: 20px"
            :style="
              $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px; '
            "
          >
            <div class="rentBusDescription" style="padding: 16px; height: 100%">
              <p class="rentBusTitle">
                {{ "bus_rental_first_fact_title" | localize }}
              </p>
              <p class="rentBusText">
                {{ "bus_rental_first_fact_text" | localize }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="py-0"
            style="margin-bottom: 20px"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px; '"
          >
            <div class="rentBusDescription" style="padding: 16px; height: 100%">
              <p class="rentBusTitle">
                {{ "bus_rental_second_fact_title" | localize }}
              </p>
              <p class="rentBusText">
                {{ "bus_rental_second_fact_text" | localize }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="py-0"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-bottom: 20px'
                : 'padding-right: 10px; '
            "
          >
            <div class="rentBusDescription" style="padding: 16px; height: 100%">
              <p class="rentBusTitle">
                {{ "bus_rental_third_fact_title" | localize }}
              </p>
              <p class="rentBusText">
                {{ "bus_rental_third_fact_text" | localize }}
              </p>
            </div>
          </v-col>
          <v-col
            cols="12"
            xl="6"
            lg="6"
            md="6"
            sm="12"
            class="py-0"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px; '"
          >
            <div class="rentBusDescription" style="padding: 16px; height: 100%">
              <p class="rentBusTitle">
                {{ "bus_rental_fourth_fact_title" | localize }}
              </p>
              <p class="rentBusText">
                {{ "bus_rental_fourth_fact_text" | localize }}
              </p>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters align="center" style="margin: 140px 0px 70px 0px">
          <v-col
            class="py-0"
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            :style="
              $vuetify.breakpoint.smAndDown
                ? 'margin-bottom: 20px;'
                : 'padding-right: 10px'
            "
          >
            <img
              src="@/assets/img/rentBusImg2.png"
              alt="rent img"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '380px'"
              :height="$vuetify.breakpoint.smAndDown ? 'max-content' : '500px'"
            />
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            xl="8"
            lg="8"
            md="8"
            sm="12"
            :style="$vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px'"
          >
            <div
              class="textOnBackground"
              style="
                width: 100%;
                border-radius: 10px;
                padding: 4px 0px;
                margin: 0px;
              "
            >
              {{ "rent_bus_head_label" | localize }}
            </div>
            <v-row no-gutters align="center">
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="py-0"
                style="margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px; '
                "
              >
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'name_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.name"
                  :error-messages="nameError"
                />
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="py-0"
                style="margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px; '
                "
              >
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'phone_number_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.phone_number"
                  :error-messages="rentFormPhoneNumberError"
                  v-mask="'+###############'"
                />
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="py-0"
                style="margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px; '
                "
              >
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'departure_place_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.departure_city"
                  :error-messages="departureCityError"
                />
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="py-0"
                style="margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px; '
                "
              >
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'quantity_person_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.quantity_people"
                  :error-messages="quantityPeopleError"
                  type="number"
                  hide-spin-buttons
                  @keypress="isNumber"
                />
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="py-0"
                style="margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-right: 10px; '
                "
              >
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'quantiy_day_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.quantity_day"
                  :error-messages="quantityDayError"
                  @keypress="isNumber"
                />
              </v-col>
              <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="py-0"
                style="margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown ? '' : 'padding-left: 10px; '
                "
              >
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'company_name_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.company"
                  :error-messages="companyError"
                />
              </v-col>
              <v-col cols="12" class="py-0" style="margin-top: 20px">
                <v-text-field
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'route_label' | localize"
                  style="border-radius: 10px"
                  height="48px"
                  hide-details
                  v-model="rentBusForm.route"
                  :error-messages="routeError"
                />
              </v-col>
              <v-col cols="12" class="py-0" style="margin-top: 20px">
                <v-textarea
                  color="#E2E2E2"
                  background-color="#FFF"
                  outlined
                  dense
                  :placeholder="'type_here' | localize"
                  :label="'additional_label' | localize"
                  style="border-radius: 10px"
                  height="100px"
                  hide-details
                  v-model="rentBusForm.additional"
                  :error-messages="additionalError"
                />
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <submit-button
                style="height: 48px !important; margin-top: 20px"
                :style="
                  $vuetify.breakpoint.smAndDown
                    ? 'width: 100% !important;'
                    : 'width: 280px !important;'
                "
                @click="rentBus"
                >{{ "order_btn" | localize }}</submit-button
              >
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
import SubmitButton from "../../UI/Buttons/submitButton.vue";
import PageStep from "../../UI/pageStep.vue";
import callbackService from "@/requests/User/callbackService.js";
import SuccessSnackbar from "../../UI/Snackbars/successSnackbar.vue";
export default {
  mixins: [validationMixin],
  components: {
    PageStep,
    SubmitButton,
    SuccessSnackbar,
  },
  data: () => ({
    phone_number: "",
    rentBusForm: {
      name: "",
      phone_number: "",
      departure_city: "",
      quantity_people: "",
      quantity_day: "",
      company: "",
      route: "",
      additional: "",
    },
    showSuccessSnackbar: false,
    snackbarText: "",
  }),
  validations: {
    phone_number: {
      required,
      minLength: minLength(10),
    },
    rentBusForm: {
      name: {
        required,
      },
      phone_number: {
        required,
        minLength: minLength(10),
      },
      departure_city: {
        required,
      },
      quantity_people: {
        required,
      },
      quantity_day: {
        required,
      },
      company: {
        required,
      },
      route: {
        required,
      },
      additional: {
        required,
      },
    },
  },
  methods: {
    async callback() {
      this.$v.phone_number.$touch();
      if (!this.$v.phone_number.$invalid) {
        let form = new FormData();
        form.append("phone_number", this.phone_number.replace(/\D+/g, ""));
        await callbackService.sendCallback(form).then((res) => {
          if (res.status == "Success") {
            this.phone_number = "";
            this.$v.phone_number.$reset();
            this.snackbarText =
              "Повідомлення успішно надіслане. Наш менеджер скоро звя'яжеться з вами";
            this.showSuccessSnackbar = true;
            setTimeout(() => {
              this.showSuccessSnackbar = false;
            }, 3000);
          }
        });
      }
    },
    async rentBus() {
      this.$v.rentBusForm.$touch();
      if (!this.$v.rentBusForm.$invalid) {
        let form = new FormData();
        form.append("name", this.rentBusForm.name);
        form.append(
          "phone_number",
          this.rentBusForm.phone_number.replace(/\D+/g, "")
        );
        form.append("departure", this.rentBusForm.departure_city);
        form.append("person_count", this.rentBusForm.quantity_people);
        form.append("day_count", this.rentBusForm.quantity_day);
        form.append("company_name", this.rentBusForm.company);
        form.append("message", this.rentBusForm.additional);
        await callbackService.sendReserve(form).then((res) => {
          if (res.status == "Success") {
            this.rentBusForm = {
              name: "",
              phone_number: "",
              departure_city: "",
              quantity_people: "",
              quantity_day: "",
              company: "",
              route: "",
              additional: "",
            };
            this.$v.rentBusForm.$reset();
            this.snackbarText =
              "Повідомлення успішно надіслане. Наш менеджер скоро звя'яжеться з вами";
            this.showSuccessSnackbar = true;
            setTimeout(() => {
              this.showSuccessSnackbar = false;
            }, 3000);
          }
        });
      }
    },
    isNumber(evt) {
      const isNumber = isFinite(evt.key);
      if (!isNumber) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  computed: {
    phoneNumberError() {
      const errors = [];
      let field = this.$v.phone_number;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    nameError() {
      const errors = [];
      let field = this.$v.rentBusForm.name;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    rentFormPhoneNumberError() {
      const errors = [];
      let field = this.$v.rentBusForm.phone_number;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    departureCityError() {
      const errors = [];
      let field = this.$v.rentBusForm.departure_city;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    quantityPeopleError() {
      const errors = [];
      let field = this.$v.rentBusForm.quantity_people;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    quantityDayError() {
      const errors = [];
      let field = this.$v.rentBusForm.quantity_day;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    companyError() {
      const errors = [];
      let field = this.$v.rentBusForm.company;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    routeError() {
      const errors = [];
      let field = this.$v.rentBusForm.route;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
    additionalError() {
      const errors = [];
      let field = this.$v.rentBusForm.additional;
      if (!field.$dirty) {
        return errors;
      }
      !field.required && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.pageNameBackground {
  background-color: #172b69;
  background-image: url("../../../assets/img/backgroundMap.png");
  background-size: cover;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
.rentBusDescription {
  padding: 40px 0px;
  background: #f6f8fc;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-style: normal;
  line-height: normal;
  text-align: left;
  border-radius: 10px;
}
.rentBusTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.rentBusText {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
@media screen and (max-width: 1023px) {
  .textOnBackground {
    border-radius: 2px;
    background: #fed500;
    padding: 0px 4px;
    color: #144fa9;
    font-family: "MacPaw Fixel";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .textOnBackground {
    border-radius: 2px;
    background: #fed500;
    padding: 0px 4px;
    color: #144fa9;
    font-family: "MacPaw Fixel";
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-left: 12px;
  }
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 14px !important;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>