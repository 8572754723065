<template>
  <rent-bus-component />
</template>

<script>
import rentBusComponent from '../../components/User/rentBus/rentBusComponent.vue'
export default {
  components: { rentBusComponent },
  mounted(){
    this.$vuetify.goTo(0,0)
  },
}
</script>

<style>

</style>